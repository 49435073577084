<template>
	<div class="passenger-container">
		<div class="flex title">
			<div class="flex-1">用户总数：{{ totalInfo.userSum }}人</div>
			<div class="flex-1">本月月活：{{ totalInfo.activeSum }}人</div>
		</div>
		<jy-table max-height="595" :data="lists">
			<jy-table-column type="index" width="60" label="序号" align="center"> </jy-table-column>
			<jy-table-column prop="name" label="用户昵称" min-width="150"> </jy-table-column>
			<jy-table-column prop="mobile" label="手机号码" min-width="100"> </jy-table-column>
			<jy-table-column prop="province" label="注册地地址" min-width="100"> </jy-table-column>
			<jy-table-column prop="createTime" label="注册时间" min-width="150"> </jy-table-column>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageInfo.pageNum"
			:page-size="pageInfo.pageSize"
			:total="pageInfo.total"
		>
		</jy-pagination>
	</div>
</template>
<script>
export default {
	name: "statisticalAnalysisPassenger",
	data() {
		return {
			lists: [],
			totalInfo: {},
			pageInfo: { pageNum: 1, total: 0, pageSize: 10 }
		};
	},
	activated() {
		this.getTotalInfo();
		this.getUserList();
	},
	methods: {
		async getTotalInfo() {
			const url = "/crrc-palm-bus/userInfo/passengerStatistics/total";
			const res = await this.$http.post(url, {}, { baseType: "base" });
			this.totalInfo = res;
		},
		async getUserList() {
			const url = "/crrc-palm-bus/userInfo/passengerStatistics/list";
			const res = await this.$http.post(
				url,
				{ pageNum: this.pageInfo.pageNum, pageSize: this.pageInfo.pageSize },
				{ baseType: "base" }
			);
			const { records, total, current } = res;
			this.lists = records;
			this.pageInfo = { ...this.pageInfo, pageNum: current, total };
		},
		handleSizeChange(val) {
			this.pageInfo = { ...this.pageInfo, pageSize: val };
			this.getUserList();
		},
		handleCurrentChange(val) {
			this.pageInfo = { ...this.pageInfo, pageNum: val };
			this.getUserList();
		}
	}
};
</script>
<style lang="scss" scoped>
.passenger-container {
	.title {
		font-size: 20px;
	}
}
</style>
